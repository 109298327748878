import { Link } from "react-router-dom";
import { useSinglePrismicDocument } from "@prismicio/react";
import { useState, useEffect, useCallback } from "react";

import "./Navbar.scss";

const Navbar = () => {
  const [document, { state }] = useSinglePrismicDocument("navigation");
  const logo = document?.data?.logo.url;
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('up');
  const [lastScroll, setLastScroll] = useState(0);

  const handleScroll = useCallback(() => {
    const currentScroll = window.scrollY;
    
    // Determine scroll direction
    if (currentScroll > lastScroll && currentScroll > 50) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }
    
    // Set scrolled state
    if (currentScroll > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    
    setLastScroll(currentScroll);
  }, [lastScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Initial check
    handleScroll();
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const toggleMenu = () => setIsOpen(!isOpen);

  if (state === "loading") {
    return (
      <header className={`header-transparent ${scrolled ? 'scrolled' : ''}`}>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <span className="navbar-brand">Loading...</span>
          </div>
        </nav>
      </header>
    );
  }

  return (
    <header className={`header-transparent 
      ${scrolled ? 'scrolled' : ''} 
      ${scrollDirection === 'down' ? 'scroll-down' : 'scroll-up'}`}
    >
      <nav className="navbar navbar-expand-lg text-center" role="navigation">
        <div className="container">
          <Link to="/" className="navbar-brand" aria-label="Home">
            <img src={logo} className="brand-logo" alt="Site Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="fi-xwluxl-three-bars-wide"></i>
            </span>
          </button>
          <div
            className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto" role="menubar">
              <li className="nav-item" role="none">
                <Link to="/about" className="nav-link" role="menuitem">
                  About
                </Link>
              </li>
              <li className="nav-item" role="none">
                <Link to="/portfolio" className="nav-link" role="menuitem">
                  Portfolio
                </Link>
              </li>
              <li className="nav-item" role="none">
                <a 
                  href="https://newsletter.andycary.com" 
                  className="nav-link" 
                  role="menuitem"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Newsletter
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
