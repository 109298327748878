import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import HomeBuilder from "./cms/HomeBuilder";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import PortfolioDetail from "./pages/PortfolioDetail";
import Works from "./pages/Works";
import TeamDetail from "./pages/TeamDetail";
import { NotFoundPage } from "./cms/NotFoundPage";
import "./App.scss";
import { init } from "emailjs-com";
import { Toaster } from "react-hot-toast";

// Initialize EmailJS with environment variable
init(process.env.REACT_APP_EMAILJS_USER_ID);

const App = () => {
  return (
    <div className="flex flex-col min-h-screen bg-black">
      <BrowserRouter basename="/">
        <ScrollToTop />
        <Navbar />
        <main className="flex-grow">
          <Toaster 
            position="bottom-right" 
            reverseOrder={true}
            toastOptions={{
              // Customize toast styling
              className: 'bg-gray-900 text-white',
              duration: 4000,
              style: {
                border: '1px solid rgba(255, 255, 255, 0.1)',
                padding: '16px',
                color: '#fff',
              },
            }}
          />
          <Routes>
            <Route
              path="/podcast"
              element={<Navigate to="https://2020.andycary.com/podcast/" replace />}
            />
            <Route path="/" element={<HomeBuilder />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Works />} />
            <Route path="/portfolio/:id" element={<PortfolioDetail />} />
            <Route path="/about" element={<TeamDetail />} />
            <Route path="/about/:id" element={<TeamDetail />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
};

export default App;
