import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import DetailComponent from "../components/TeamDetailComponent";
import Footer from "../components/Footer";

const TeamDetail = ({ person }) => {
  return (
    <div className="flex flex-col min-h-screen bg-black">
      <main className="flex-1">
        <DetailComponent person={person} />
      </main>
      <Footer />
    </div>
  );
};

TeamDetail.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    authorLink: PropTypes.string.isRequired,
    authorTitle: PropTypes.string.isRequired,
    authorDescription: PropTypes.string.isRequired,
    paragraph1: PropTypes.string.isRequired,
    paragraph2: PropTypes.string.isRequired
  }).isRequired
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps?.id || "0";
  const person = state.team.find(person => person.id === +id);
  return {
    person: person || null
  };
};

const TeamDetailWrapper = (props) => {
  const params = useParams();
  return <TeamDetail {...props} id={params.id} />;
};

export default connect(mapStateToProps, null)(TeamDetailWrapper);
