import React from "react";
import { Link } from "react-router-dom";
import ReactiveButton from "reactive-button";
import PropTypes from 'prop-types';

const PortfolioDetailComponent = ({ item }) => {
  const { 
    title = '', 
    src = '', 
    desc = '', 
    date = '', 
    category = '', 
    link = '', 
    technologies = '' 
  } = item || {};

  return (
    <main className="container text-white mt-5">
      <div className="detail section-title mb-2">
        <div className="row g-5">
          <div className="col-sm-12 col-md-6">
            <div className="detail-image mt-5 position-relative">
              <img 
                src={src} 
                alt={`${title} project screenshot`} 
                className="banner-image rounded-lg shadow-xl w-100 hover:scale-[1.02] transition-transform duration-300"
                onError={(e) => {
                  e.target.src = '/fallback-image.jpg';
                  e.target.alt = 'Image not available';
                }}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 text-left">
            <div className="detail-info title-font-2 mt-5">
              <h1 className="work-title mb-4 text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                {title}
              </h1>
              
              <div className="mb-4 flex gap-2 flex-wrap">
                <span className="px-3 py-1 bg-gray-800 rounded-full text-sm">
                  {category}
                </span>
                <span className="px-3 py-1 bg-gray-800 rounded-full text-sm">
                  {date}
                </span>
              </div>

              <p className="description title-text mb-6 leading-relaxed text-gray-300">
                {desc}
              </p>

              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Technologies Used</h2>
                <div className="flex flex-wrap gap-2">
                  {technologies.split(',').map((tech, index) => (
                    <span 
                      key={index}
                      className="px-3 py-1 bg-blue-900/30 rounded-full text-sm border border-blue-500/30"
                    >
                      {tech.trim()}
                    </span>
                  ))}
                </div>
              </div>

              {link && (
                <div className="mb-6">
                  <a 
                    href={link} 
                    target="_blank" 
                    rel="noreferrer"
                    className="inline-block"
                  >
                    <ReactiveButton
                      idleText="View Live Site"
                      color="teal"
                      size="large"
                      rounded
                      className="hover:shadow-lg transition-shadow"
                      aria-label={`Visit ${title} website`}
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 mb-4">
        <Link to="/portfolio">
          <ReactiveButton
            idleText="← Back to Portfolio"
            color="light"
            size="medium"
            rounded
            className="hover:translate-x-[-4px] transition-transform"
            aria-label="Return to portfolio"
          />
        </Link>
      </div>
    </main>
  );
};

PortfolioDetailComponent.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    technologies: PropTypes.string.isRequired,
  }).isRequired,
};

export default PortfolioDetailComponent;
