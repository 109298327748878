import Footer from './Footer';
import PortfolioPage from '../cms/PortfolioPage';

const Portfolio = () => {
  return (
    <>
      <div className="container">
        <section className="portfolio section-title mt-2 text-white">
          <div className="row align-items-center">
            <div className="col-12 mx-auto">
              <div className="portfolio-title text-white mb-2 mt-4">
                <h2 className="title-font mt-5">Portfolio</h2>
              </div>
              <div>
                <p>
                  As a Full Stack Software Engineer, the following projects
                  showcase end-to-end products I developed to continue
                  learning and applying industry best-practices.
                </p>
              </div>
            </div>
          </div>
          <PortfolioPage />
        </section>
        <section className="about-us-area text-white py-16">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 mx-auto">
              <div className="about-us-text-area space-y-6">
                <h2 className="about-us-text title-font-3 text-3xl font-bold mb-4">
                  Making the abstract and conceptual concrete
                </h2>
                <p className="title-font-2 leading-relaxed text-gray-300">
                  Software has the power to harness our ideas, thoughts and
                  ways of doing things. My portfolio displays expressions from
                  my perspective and how I accomplish them.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Portfolio;
