import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BuilderComponent, builder } from "@builder.io/react";

import Footer from "../components/Footer";

const builderKey = process.env.REACT_APP_BUILDER_TOKEN;
builder.init(builderKey);

export default function Page() {
  const [homepage, setHomepage] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    builder
      .get("page", {
        query: {
          data: {
            uuid: 1,
          },
        },
      })
      .toPromise()
      .then((homepageData) => {
        setHomepage(homepageData);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {homepage && <BuilderComponent model="homepage" content={homepage} />}
      {!isLoading && (
        <>
          <section className="about-us-area text-white">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 mx-auto">
                  <h2>
                    <Link to="/about">Andy Cary</Link> is a Senior Software
                    Engineer specializing in building usable, performant,
                    reliable and maintainable web applications.
                  </h2>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
}
