import React, { useState, useEffect } from "react";
import { BuilderComponent, builder } from "@builder.io/react";

const builderKey = process.env.REACT_APP_BUILDER_TOKEN;
builder.init(builderKey);

// Validate builder key early
if (!builderKey) {
  console.error('Missing Builder.io API key');
}

export default function PortfolioPage() {
  const [hero, setHero] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const portfolioData = await builder
          .get("page", {
            // Be explicit about fields to fetch
            fields: 'data,id,name',
            query: {
              data: {
                uuid: 4,
              },
            },
          })
          .toPromise();
        
        if (!portfolioData) {
          throw new Error('No portfolio data found');
        }

        setHero(portfolioData);
      } catch (err) {
        setError(err.message || "Failed to load portfolio data");
        console.error("Portfolio data fetch error:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPortfolioData();
  }, []);

  if (isLoading) return <div role="status" aria-live="polite">Loading portfolio...</div>;
  if (error) return <div role="alert">{error}</div>;
  
  return hero ? (
    <BuilderComponent 
      model="page" 
      content={hero}
      options={{ includeRefs: true }}
    /> 
  ) : null;
}
