import React from "react";
import { Typography } from "@material-tailwind/react";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="relative w-full h-24 bg-gradient-to-b from-gray-900 via-gray-900 to-black border-t border-gray-800/30">
      <div className="container mx-auto px-6 h-full">
        <div className="flex flex-col items-center justify-center h-full text-center">
          <Typography
            as="span"
            className="text-gray-400 hover:text-white transition-all duration-300 text-sm font-light tracking-wider px-4"
          >
            &copy; {currentYear} Andy Cary
          </Typography>
          <div className="w-16 h-px bg-gray-800/30"></div>
          <div className="flex items-center justify-center space-x-6">
            {/* ... social icons ... */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
