import React from 'react';
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
    return (
        <main className="container text-white" style={{
            height: "100vh",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <h1>404 - Page Not Found</h1>
            <p>Oops! We couldn't find that page. <Link to="/">Return home!</Link></p>
        </main>
    );
}