import ReactiveButton from "reactive-button";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const TeamDetailComponent = (props) => {
  const {
    image,
    authorLink,
    authorTitle,
    authorDescription,
    paragraph1,
    paragraph2,
  } = props.person;

  return (
    <div className="container py-24">
      <div className="detail section-title text-white space-y-16">
        <div className="row align-items-center">
          <div className="col-md-7 mx-auto">
            <div className="detail-title">
              <h2 className="title-font text-4xl font-bold mb-8">About</h2>
            </div>
          </div>
        </div>
        <div className="row gap-y-12">
          <div className="col-md-6 mx-auto">
            <div className="detail-image-box">
              <img src={image} alt="detail" className="banner-image rounded-lg shadow-xl" />
            </div>
          </div>
          <div className="col-md-6 mx-auto">
            <div className="detail-info text-left title-font-2 space-y-6">
              <h2 className="work-title text-3xl font-semibold mb-4">Andrew (Andy) Cary</h2>
              <div className="space-y-4">
                <p className="description title-text leading-relaxed">{paragraph1}</p>
                <p className="description title-text leading-relaxed">{paragraph2}</p>
              </div>
              <p className="description title-text text-gray-400">
                <small>
                  Author:{" "}
                  <a
                    href={authorLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-300"
                  >
                    {authorTitle}
                  </a>
                  <br />
                  {authorDescription}
                </small>
              </p>
            </div>
            <div className="flex gap-4 mt-8">
              <a
                href="https://www.linkedin.com/in/andrewdcary/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactiveButton
                  idleText="LinkedIn"
                  color="dark"
                  size="large"
                  rounded
                  className="hover:shadow-lg transition-shadow"
                />
              </a>
              <a
                href="https://github.com/acary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactiveButton
                  idleText="GitHub"
                  color="info"
                  size="large"
                  rounded
                  className="hover:shadow-lg transition-shadow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-16 text-center">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="text-white">
              <Link to="/contact" className="nav-link hover:opacity-80 transition-opacity">
                <h4 className="title-font text-2xl font-semibold">
                  Let's build the future together
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

TeamDetailComponent.propTypes = {
  person: PropTypes.shape({
    image: PropTypes.string.isRequired,
    authorLink: PropTypes.string.isRequired,
    authorTitle: PropTypes.string.isRequired,
    authorDescription: PropTypes.string.isRequired,
    paragraph1: PropTypes.string.isRequired,
    paragraph2: PropTypes.string.isRequired
  }).isRequired
};

export default TeamDetailComponent;
